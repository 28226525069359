import React, {useEffect} from 'react'
import {Input} from "reactstrap";
import i18next from 'i18next'
import cookies from 'js-cookie'

export default function SwitchLanguages ({setLanguage}) {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'ru',
      name: 'Русский'
    }
  ]
  useEffect(()=>{
    setLanguage(currentLanguageCode)
  }, [])

  const handler = (e) => {
    i18next.changeLanguage(e)
    setLanguage(e)
  }
  return (
      <Input className='languages-select' type="select" name='Lang' defaultValue={currentLanguageCode} onChange={(e) => handler(e.target.value)  }>
        {languages.map(({ code, name}) => {
          if (code === currentLanguageCode) return <option key={code}  disabled value={code}>{name}</option>
          return <option key={code} value={code}>{name}</option>
        })}
      </Input>
  )
}

