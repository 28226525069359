import {lazy} from 'react';
const Dashboard = lazy(() => import("./views/Dashboard"))
const Settings = lazy(() => import("./views/Settings"))
const ChargingStations = lazy(() => import("./views/ChargingStations"))
const ChargingStation = lazy(() => import("./views/ChargingStation"))
const Companies = lazy(() => import("./views/Companies"))
const Employees = lazy(() => import("./views/Employees"))
const Customers = lazy(() => import("./views/Customers"))
const Customer = lazy(() => import("./views/Customer"))
const IdTokens = lazy(() => import("./views/IdTokens"))
const ChargingSessions = lazy(() => import("./views/ChargingSessions"))
const ChargingSession = lazy(() => import("./views/ChargingSession"))
const Payments = lazy(() => import("./views/Payments"))
const Tariffs = lazy(() => import("./views/Tariffs"))
const Tariffs2 = lazy(() => import("./views/Tariffs2"))
const Orders = lazy(() => import("./views/Orders"))
const Reservations = lazy(() => import("./views/Reservations"))
const Tariff = lazy(() => import("./views/Tariffs2/Tariff2"))
const Reports = lazy(() => import("./views/Reports"))

const routes = [
  {
    path: "/dashboard",
    name: "Дэшборд",
    component: Dashboard,
    exact: true
  },
  {
    path: "/settings",
    name: "Настройки",
    component: Settings
  },
  {
    path: "/payments",
    name: "Платежи",
    exact: true,
    component: Payments
  },
  {
    path: [
      "/charging-stations"
    ],
    exact: true,
    name: "Зарядные станции",
    component: ChargingStations
  },
  {
    path: [
      "/charging-stations/:id",
    ],
    exact: true,
    name: "Зарядная станция",
    component: ChargingStation
  },
  {
    path: "/customers",
    name: "Пользователи",
    exact: true,
    component: Customers,
  },
  {
    path: "/customers/:email",
    name: "Пользователь",
    exact: true,
    component: Customer,
  },
  {
    path: "/tariffs",
    name: "Тарифные планы",
    exact: true,
    component: Tariffs,
  },
  {
    path: "/reports",
    name: "Отчёты",
    exact: true,
    component: Reports,
  },
  // {
  //   path: "/tariffsocpi",
  //   name: "Тарифные планы2",
  //   exact: true,
  //   component: Tariffs2,
  // },
  {
    path: "/tariffs/:id",
    name: "Тарифные планы2",
    exact: true,
    component: Tariff
  },
  {
    path: "/charging-sessions",
    name: "Зарядные сессии",
    exact: true,
    component: ChargingSessions
  },
  {
    path: "/charging-sessions/:id",
    name: "Зарядная сессия",
    exact: true,
    component: ChargingSession
  },
  {
    path: "/reservations",
    name: "Бронирования",
    exact: true,
    component: Reservations,
  },
  {
    path: "/id-tokens",
    name: "ID Токены",
    exact: true,
    component: IdTokens,
  },
  {
    path: "/orders",
    name: "Заказы",
    exact: true,
    component: Orders,
  },
  {
    path: "/companies",
    name: "Компании",
    component: Companies
  },
  {
    path: "/employees",
    name: "Сотрудники",
    component: Employees
  }
];

export default routes;
