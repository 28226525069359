import axios from "axios";
import {getToken} from "../utils/localstorage";

const instance = axios.create({
  baseURL: 'zoi/api/v2',
  headers: {
    'Content-type': 'application/json'
  }
});

instance.interceptors.request.use(function (config) {

  // Временный костыль, ждём когда бэк подготовит нужные эндпоинты.
  if(config.url.includes('plots') || config.url.includes('auth')) config.baseURL = 'zoi/resources/'
  // --------------------------------------------------------------

  const token = getToken();
  if (token) {
    config.headers['Access-Token'] = token;
  }
  return config;
});

export default instance
