export const setToken = tokenId => {
  localStorage.setItem('token_id', tokenId)
}

export const getToken = () => {
  return localStorage.getItem('token_id')
}

export const logout = () => {
  localStorage.removeItem('token_id')
}
