import instance from "./config";

const loginService = {
  login: (email, password) => {
    return instance.post('/auth', {email, password}).then(({data}) => data)
  },
  changePassword: (oldPassword, newPassword) => {
    return instance.post('auth/password/change', {oldPassword, newPassword}).then(res => res.data)
  }
}

export default loginService
