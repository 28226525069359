export const navigation = (t) => {
  return {
    items: [
      {
        name: t('dashboard'),
        url: "/dashboard",
        icon: "cil-bar-chart",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_PAYMENT'
      },
      {
        name: t('payments'),
        url: "/payments",
        icon: "cil-credit-card",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_PAYMENT'
      },
      {
        name: t('charging-stations-header'),
        url: "/charging-stations",
        icon: "fa fa-bolt fa-lg",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_CHARGING_STATION'
      },
      {
        name: t('customers-header'),
        url: "/customers",
        icon: "fa fa-address-book",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_CUSTOMER'
      },
      {
        name: t('tariff-plans'),
        url: "/tariffs",
        icon: "cil-dollar",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_TARIFF'
      },
      // {
      //   name: "Тарифные планы2",
      //   url: "/tariffsocpi",
      //   icon: "cil-dollar",
      //   badge: {
      //     variant: "info",
      //     text: ""
      //   },
      //   permission: 'VIEW_TARIFF'
      // },
      {
        name: t('charging-sessions'),
        url: "/charging-sessions",
        icon: "cil-transfer",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_CHARGING_SESSION'
      },
      {
        name: t('reservations-header'),
        url: "/reservations",
        icon: "cil-calendar",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_RESERVATION'
      },
      {
        name: t('id-tokens-header'),
        url: "/id-tokens",
        icon: "fa fa-id-card-o",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_ID_TOKEN'
      },
      {
        name: t('orders-header'),
        url: "/orders",
        icon: "cil-cart",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_ORDER'
      },
      {
        name: t('companies-header'),
        url: "/companies",
        icon: "fa fa-building-o ",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_COMPANY'
      },
      {
        name: t('employees-header'),
        url: "/employees",
        icon: "cil-user",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_EMPLOYEE'
      },
      {
        name: t('reports-header'),
        url: "/reports",
        icon: "cil-chart-line",
        badge: {
          variant: "info",
          text: ""
        },
        permission: 'VIEW_TARIFF'
      },
      {
        name: t('settings'),
        url: "/settings",
        icon: "cil-settings",
        badge: {
          variant: "info",
          text: ""
        }
      },
      {
        name: t('exit'),
        url: "/logout",
        icon: "cil-account-logout",
        badge: {
          variant: "info",
          text: ""
        },
        class: "navside-exit-item"
      }
    ]
  }
};
