import React, {Suspense} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import DefaultLayout from './views/DefaultLayout';
import './App.scss';
import {QueryClient, QueryClientProvider} from "react-query";
import {ToastContainer} from "react-toastify";
import {AuthProvider} from "./auth/AuthProvider";

// import {ReactQueryDevtools} from 'react-query/devtools'


const AppBodyComponent = () => {
  const loading = () => (
    <div className='appAuthLoader'>
      Авторизация...
    </div>
  )

  return <Suspense fallback={loading()}>
    <HashRouter>
      <Switch>
        <Route exact path={'/dashboard'} component={DefaultLayout}/>
        <Route path={'/'} component={DefaultLayout}/>
      </Switch>
    </HashRouter>
  </Suspense>
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
})

const App = () => {

  return (
    <>
      <ToastContainer className={'toastContainerStyled'}/>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppBodyComponent/>
        </AuthProvider>
        {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
      </QueryClientProvider>
    </>
  )
}

export default App
