import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import styled from "styled-components";

import PropTypes from "prop-types";

const ButtonsWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
`;

class ConfirmDialog extends React.Component {
  render() {
    const { toggle, isOpen, onConfirm, text, buttonText, buttonTextCancel, buttonTextSave } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="sm" centered>
        <ModalBody>
          {text}
          <ButtonsWrapper>
            <Button style={{ marginRight: "12px" }} onClick={toggle}>
              {buttonTextCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onConfirm();
                toggle();
              }}
            >
              {buttonText ? buttonText : buttonTextSave}
            </Button>
          </ButtonsWrapper>
        </ModalBody>
      </Modal>
    );
  }
}

ConfirmDialog.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  text: PropTypes.string
};

export default ConfirmDialog;
